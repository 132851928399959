<!--@Time : 2021/11/16 10:44-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<p class="text-center text-h6">Home Life Contest Scores</p>
			</v-col>
			<v-col cols="12" md="12" lg="12">
				<v-container>
					<div id="container" style="position: relative">
						<Loading
							v-if="!data.length"
							:loading="loading"
						></Loading>
					</div>
				</v-container>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<p class="text-h6 mt-8 mb-4 font-weight-bold text-uppercase">
					Your Performance
				</p>
				<v-alert
					prominent
					type="success"
					class="text-h6 font-weight-medium"
					v-text="'Score Earned: ' + Number(score).toFixed(3)"
				></v-alert>
			</v-col>
		</v-row>
		<v-row v-else></v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Refrigerator (15%)</v-card-title>
					<v-card-text>
						<p class="text-center text-h6">
							Refrigerator Measurements
						</p>
						<v-container>
							<div id="container_refrigerator">
								<Loading
									v-if="!refrigerator_data.length"
									:loading="loading"
								></Loading>
							</div>
						</v-container>
						<SubContestLabel
							class="mt-5"
							:score="RefrigeratorScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Freezer (15%)</v-card-title>
					<v-card-text>
						<p class="text-center text-h6">Freezer Measurements</p>
						<v-container>
							<div id="container_freezer">
								<Loading
									v-if="!freezer_data.length"
									:loading="loading"
								></Loading>
							</div>
						</v-container>
						<SubContestLabel
							class="mt-5"
							:score="FreezerScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Clothes Washer (20%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="clothesWasherHeaders"
							:items="clothesWasherDesserts"
							:loading="clothesWasherLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('clothes_washer')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="clothesWasherScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Clothes Drying (20%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="clothesDryingHeaders"
							:items="clothesDryingDesserts"
							:loading="clothesDryingLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('clothes_drying')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="clothesDryingScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Dinner Party (20%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="dinnerPartyHeaders"
							:items="dinnerPartyDesserts"
							:loading="dinnerPartyLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('dinner_party')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="dinnerPartyScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Movie Night (10%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="movieNightHeaders"
							:items="movieNightDesserts"
							:loading="movieNightLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('movie_night')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="movieNightScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
			<v-card>
				<v-card-title class="mb-3">New Item</v-card-title>
				<v-card-text>
					<v-row>
            <v-col cols="6">
              <v-datetime-picker
                  v-model="editedItem.starting_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  label="Starting Time"
              ></v-datetime-picker>
            </v-col>
            <v-col cols="6">
              <v-datetime-picker
                  v-model="editedItem.ending_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  label="Ending Time"
              ></v-datetime-picker>
            </v-col>
						<template v-if="task === 'clothes_washer'">
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.completeness"
									label="Completeness"
                  suffix="%"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'clothes_drying'">
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.starting_weight"
									label="Starting Weight"
									suffix="kg"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.ending_weight"
									label="Ending Weight"
									suffix="kg"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'dinner_party'">
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.guests"
									label="Guests"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="editedItem.comments"
									label="Comments"
								></v-textarea>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.score_earned"
									label="Score Earned"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'movie_night'">
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.movie_name"
									label="Move Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.guests"
									label="Guests"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="editedItem.comments"
									label="Comments"
								></v-textarea>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.score_earned"
									label="Score Earned"
								></v-text-field>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>Cancel</v-btn
					>
					<v-btn
						v-if="save"
						color="primary"
						text
						small
						@click="saveData"
						>Save</v-btn
					>
					<v-btn v-else color="primary" text small @click="updateData"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="closeDelete"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteItemConfirm"
						>Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "../../components/Header";
import { Chart } from "@antv/g2";
import { DataView } from "@antv/data-set";
import Loading from "@/components/Loading";
import {
	addContest,
	delContest,
	listContest,
	updateContestPatch,
} from "../../api/solar/contest";
import { Message } from "element-ui";
import SubContestLabel from "@/components/SubContestLabel";
import { listHomeLifeScore } from "../../api/solar/sensor";
import moment from "moment";

export default {
	name: "Homelife",
	components: { Loading, Header, SubContestLabel },
	data() {
		return {
			data: [],
			chart: null,
			refrigerator_chart: null,
			freezer_chart: null,
			refrigerator_data: [],
			freezer_data: [],
			loading: "loading...",

			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 2,

			clothesWasherHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
        { text: "Completeness", value: "completeness" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			clothesWasherDesserts: [],

			clothesDryingHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Weight (kg)", value: "starting_weight" },
				{ text: "Ending Weight (kg)", value: "ending_weight" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			clothesDryingDesserts: [],

			dinnerPartyHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Guests", value: "guests" },
				{ text: "Comments", value: "comments" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			dinnerPartyDesserts: [],

			movieNightHeaders: [
				{ text: "#", value: "no" },
				{ text: "Movie Name", value: "movie_name" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Guests", value: "guests" },
				{ text: "Comments", value: "comments" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			movieNightDesserts: [],

			clothesWasherLoading: false,
			clothesDryingLoading: false,
			dinnerPartyLoading: false,
			movieNightLoading: false,

			score: 0,
			RefrigeratorScore: 0,
			FreezerScore: 0,
			clothesWasherScore: 0,
			clothesDryingScore: 0,
			dinnerPartyScore: 0,
			movieNightScore: 0,

			dialog: false,
			dialogDelete: false,
			save: true,
			Date: {},
			task: "",

			editedItem: {
        starting_time: null,
        ending_time: null,
				volume_flow: null,
				average_temperature: null,
				observer_name: null,
				decathlete_name: null,
				starting_weight: null,
				ending_weight: null,
        completeness: null,
				score_earned: null,
				guests: null,
				comments: null,
				movie_name: null,
			},

      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
		};
	},

	created() {
		this.getScore();
	},

	mounted() {
		this.setChart();
		this.setRefrigeratorChart();
		this.setFreezerChart();
		this.initWebSocket();
		this.getContest(null);
	},

	beforeDestroy() {
		//离开路由之前断开websocket连接
		this.websock.close();
    this.chart.destroy();
    this.freezer_chart.destroy();
    this.refrigerator_chart.destroy();
	},

	watch: {
		"$store.getters.team": {
			handler() {
        this.getScore()
				this.getContest(null);
				this.websock.close();
				this.refrigerator_data = [];
				this.freezer_data = [];
				this.refrigerator_chart.changeData(this.refrigerator_data);
				this.freezer_chart.changeData(this.freezer_data);
				this.initWebSocket();
			},
		},
	},

	computed: {},

	methods: {
		async setChart() {
			this.chart = new Chart({
				container: "container",
				autoFit: true,
				height: 500,
			});
			this.chart.data(this.data);
			this.chart.scale("score", {
				min: 0,
				max: 100,
				tickInterval: 10,
			});
			this.chart.legend({
				position: "bottom",
				flipPage: false,
				items: [
					{
						name: "Clothes Washer",
						marker: { style: { fill: "#f19c94" } },
					},
					{
						name: "Clothes Drying",
						marker: { style: { fill: "#6395F9" } },
					},
					{
						name: "Dinner Party",
						marker: { style: { fill: "#a3e1c8" } },
					},
					{
						name: "Movie Night",
						marker: { style: { fill: "#657798" } },
					},
					{ name: "Freezer", marker: { style: { fill: "#c5a3e7" } } },
					{
						name: "Refrigerator",
						marker: { style: { fill: "#c4a46a" } },
					},
				],
			});
			this.chart.coordinate().transpose();
			this.chart.tooltip({
        shared: true,
        showMarkers: false,
        customItems: (items) => {
          items.forEach((item)=>{
            item.value = Number(item.value).toFixed(3)
          })
          return items;
        },
			});
			this.chart.interaction("active-region");
			this.chart
				.interval()
				.adjust("stack")
				.position("team*score")
				.color("contest*team", (contest) => {
					if (contest === "Clothes Washer") {
						return "#f19c94";
					}
					if (contest === "Clothes Drying") {
						return "#6395F9";
					}
					if (contest === "Dinner Party") {
						return "#a3e1c8";
					}
					if (contest === "Movie Night") {
						return "#657798";
					}
					if (contest === "Freezer") {
						return "#c5a3e7";
					}
					if (contest === "Refrigerator") {
						return "#c4a46a";
					}
					if (contest === "Total") {
						return "rgba(255,255,255,0)";
					}
				})
				.label("score", (score) => {
					if (score === 0) {
						return null;
					}
					return {
            content: score.toFixed(3),
						fill: "#07021a",
						position: "left",
					};
				});
			this.chart.render();
		},
		async setRefrigeratorChart() {
			this.refrigerator_chart = new Chart({
				container: "container_refrigerator",
				autoFit: true,
				height: 500,
			});

			this.refrigerator_chart.data(this.refrigerator_data);

			this.refrigerator_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				refrigerator_value: {
					min: -10,
					max: 30,
				},
			});

      this.refrigerator_chart.axis("refrigerator_value", {
        title: {
          text: "Temperature (°C)",
        },
        /* label: {
          formatter:(val) =>  {
            return val + '°C';
          },
        }, */
      });

			this.refrigerator_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.refrigerator_chart.legend({
				flipPage: false,
			});

			this.refrigerator_chart
				.line()
				.position("time*refrigerator_value")
				.color("sensor_type")
				.shape("smooth");

			this.refrigerator_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.refrigerator_chart.render();
		},
		async setFreezerChart() {
			this.freezer_chart = new Chart({
				container: "container_freezer",
				autoFit: true,
				height: 500,
			});

			this.freezer_chart.data(this.freezer_data);

			this.freezer_chart.scale({
				time: {
					type: "time",
					mask: "YYYY-MM-DD HH:mm",
				},
				freezer_value: {
					min: -40,
					max: 10,
				},
			});

      this.freezer_chart.axis("freezer_value", {
        title: {
          text: "Temperature (°C)",
        },
        /* label: {
          formatter:(val) =>  {
            return val + '°C';
          },
        }, */
      });

			this.freezer_chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.freezer_chart.legend({
				flipPage: false,
			});

			this.freezer_chart
				.line()
				.position("time*freezer_value")
				.color("sensor_type")
				.shape("smooth");
			this.freezer_chart.option("slider", {
				trendCfg: {
					isArea: false,
				},
			});
			this.freezer_chart.render();
		},

		//初始化websocket
		async initWebSocket() {
			let url;
			if (this.$store.getters.team) {
				url = `${process.env.VUE_APP_WS_URL}/ws/sensordata/?token=${this.$store.getters.token}`;
			} else {
				url = `${process.env.VUE_APP_WS_URL}/ws/allsensor/?token=${this.$store.getters.token}`;
			}
			this.websock = new WebSocket(url);
			this.websock.onmessage = this.WebsocketOnMessage;
			this.websock.onopen = this.WebsocketOnOpen;
			this.websock.onerror = this.WebsocketOnError;
			this.websock.onclose = this.WebsocketClose;
		},
		WebsocketOnOpen(e) {
			console.log("建立连接", e);
			//连接建立之后执行send方法发送数据
			let actions = { type_id: "home_life" };
			if (this.$store.getters.team) {
				actions["team_id"] = this.$store.getters.team.id;
			}
			this.WebsocketSend(JSON.stringify(actions));
		},
		WebsocketOnError() {
			//连接建立失败重连
			this.initWebSocket();
		},
		WebsocketOnMessage(event) {
			//数据接收
			this.refrigerator_data = [];
			this.freezer_data = [];
			const data = JSON.parse(event.data);
			Object.values(data["sensor_data"]).forEach((value) => {
				this.refrigerator_data.push(...value["Refrigerator"]);
				this.freezer_data.push(...value["Freezer"]);
			});
			this.refrigerator_chart.changeData(this.refrigerator_data);
			this.freezer_chart.changeData(this.freezer_data);
		},
		WebsocketSend(Data) {
			//数据发送
			this.websock.send(Data);
		},
		WebsocketClose(e) {
			//关闭
			console.log("断开连接", e);
		},

		getScore() {
			listHomeLifeScore()
				.then((res) => {
					this.data = new DataView().source(res).transform({
						type: "fold",
						fields: [
							"Clothes Washer",
							"Clothes Drying",
							"Dinner Party",
							"Movie Night",
							"Freezer",
							"Refrigerator",
							"Total",
						], // 展开字段集
						key: "contest", // key字段
						value: "score", // value字段
						retains: ["team"], // 保留字段集，默认为除 fields 以外的所有字段
					}).rows;
					if (this.$store.getters.team) {
						res.forEach((item) => {
							if (
								item.team === this.$store.getters.team.name_en
							) {
								this.clothesWasherScore = item["Clothes Washer"];
								this.clothesDryingScore = item["Clothes Drying"];
								this.dinnerPartyScore = item["Dinner Party"];
								this.movieNightScore = item["Movie Night"];
								this.FreezerScore = item["Freezer"];
								this.RefrigeratorScore = item["Refrigerator"];
								this.score = item.Total;
							}
						});
					}
					this.chart.changeData(this.data);
				})
				.catch();
		},

		// 清除数据
		clearData() {
			this.dialog = false;
			this.Date = {};
			this.editedItem = {
        starting_time: null,
        ending_time: null,
				volume_flow: null,
				average_temperature: null,
				observer_name: null,
				decathlete_name: null,
				starting_weight: null,
				ending_weight: null,
        completeness: null,
				score_earned: null,
				guests: null,
				comments: null,
				movie_name: null,
			};
		},

		// 打开编辑页面
		editItem(item) {
			this.save = false;
			this.task = item.task;
			this.Date = item;
      this.editedItem.starting_time = item.starting_time;
			this.editedItem.ending_time = item.ending_time;
			this.editedItem.starting_weight = item.starting_weight;
			this.editedItem.ending_weight = item.ending_weight;
			this.editedItem.observer_name = item.observer_name;
			this.editedItem.decathlete_name = item.decathlete_name;
			this.editedItem.guests = item.guests;
			this.editedItem.comments = item.comments;
      this.editedItem.completeness = item.completeness
			this.editedItem.score_earned = item.score_earned;
			this.editedItem.movie_name = item.movie_name;
			this.dialog = true;
		},

		// 打开删除页面
		deleteItem(item) {
			this.dialogDelete = true;
			this.Date = item;
		},

		// 关闭删除页面
		closeDelete() {
			this.dialogDelete = false;
			this.clearData();
		},

		// 打开添加页面
		addItem(task) {
			this.clearData();
			this.Date.task = task;
			this.task = task;
			this.save = true;
			this.dialog = true;
		},

		// 获取得分
		getContest(task) {
			if (this.$store.getters.team) {
				const params = {
					team: this.$store.getters.team.id,
					type: "homelife",
					pagesize: 100,
				};
				if (task !== null) {
					params["task"] = task;
					switch (task) {
						case "clothes_washer":
							this.clothesWasherLoading = true;
							break;
						case "clothes_drying":
							this.clothesDryingLoading = true;
							break;
						case "dinner_party":
							this.dinnerPartyLoading = true;
							break;
						case "movie_night":
							this.movieNightLoading = true;
							break;
					}
					this.itemLoading = true;
					listContest(params).then((res) => {
						switch (task) {
							case "clothes_washer":
								this.clothesWasherDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.clothesWasherDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
                    completeness: data.completeness,
										score_earned: item.score.toFixed(3),
									});
								});
								this.clothesWasherLoading = false;
								break;
							case "clothes_drying":
								this.clothesDryingDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.clothesDryingDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										starting_weight: data.starting_weight,
										ending_weight: data.ending_weight,
										score_earned: item.score.toFixed(3),
									});
								});
								this.clothesDryingLoading = false;

								break;
							case "dinner_party":
								this.dinnerPartyDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.dinnerPartyDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										guests: data.guests,
										comments: data.comments,
										score_earned: item.score.toFixed(3),
									});
								});
								this.dinnerPartyLoading = false;
								break;
							case "movie_night":
								this.movieNightDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.movieNightDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										guests: data.guests,
										comments: data.comments,
										movie_name: data.movie_name,
										score_earned: item.score.toFixed(3),
									});
								});
								this.movieNightLoading = false;
								break;
						}
					});
				} else {
					this.clothesWasherLoading = true;
					this.clothesDryingLoading = true;
					this.dinnerPartyLoading = true;
					this.movieNightLoading = true;
					listContest(params).then((res) => {
						this.clothesWasherDesserts = [];
						this.clothesDryingDesserts = [];
						this.dinnerPartyDesserts = [];
						this.movieNightDesserts = [];
						res["results"].forEach((item) => {
							const data = JSON.parse(item["data"]);
							switch (item["task"]) {
								case "clothes_washer":
									this.clothesWasherDesserts.push({
										no:
											this.clothesWasherDesserts.length +
											1,
										id: item.id,
                    task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
                    completeness: data.completeness,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "clothes_drying":
									this.clothesDryingDesserts.push({
										no:
											this.clothesDryingDesserts.length +
											1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										starting_weight: data.starting_weight,
										ending_weight: data.ending_weight,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "dinner_party":
									this.dinnerPartyDesserts.push({
										no: this.dinnerPartyDesserts.length + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										guests: data.guests,
										comments: data.comments,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "movie_night":
									this.movieNightDesserts.push({
										no: this.movieNightDesserts.length + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										guests: data.guests,
										comments: data.comments,
										movie_name: data.movie_name,
										score_earned: item.score.toFixed(3),
									});
									break;
							}
						});
						this.clothesWasherLoading = false;
						this.clothesDryingLoading = false;
						this.dinnerPartyLoading = false;
						this.movieNightLoading = false;
					});
				}
			}
		},

		// 保存
		saveData() {
			if (this.editedItem.starting_time == null || this.editedItem.ending_time == null) {
        Message({
					message: "未选择时间",
					type: "error",
				});
				return;
			} else {
        this.editedItem.starting_time = moment(this.editedItem.starting_time).format("YYYY-MM-DD HH:mm:ss")
        this.editedItem.ending_time = moment(this.editedItem.ending_time).format("YYYY-MM-DD HH:mm:ss")
      }
			addContest({
				team: this.$store.getters.team.id,
				type: "homelife",
				task: this.Date.task,
				score: this.editedItem.score_earned,
				data: JSON.stringify(this.editedItem),
			}).then(() => {
				Message({
					message: "Upload Successful",
					type: "success",
				});
				let actions = { type_id: "home_life" };
				if (this.$store.getters.team) {
					actions["team_id"] = this.$store.getters.team.id;
				}
				this.getScore();
				this.getContest(this.Date.task);
			});
			this.dialog = false;
		},

		// 更新
		updateData() {
      if (this.editedItem.starting_time == null || this.editedItem.ending_time == null) {
        Message({
          message: "未选择时间",
          type: "error",
        });
        return;
      } else {
        this.editedItem.starting_time = moment(this.editedItem.starting_time).format("YYYY-MM-DD HH:mm:ss")
        this.editedItem.ending_time = moment(this.editedItem.ending_time).format("YYYY-MM-DD HH:mm:ss")
      }
			updateContestPatch(this.Date.id, {
				score: this.editedItem.score_earned,
				data: JSON.stringify(this.editedItem),
			}).then(() => {
				Message({
					message: "Update Successful",
					type: "success",
				});
				let actions = { type_id: "home_life" };
				if (this.$store.getters.team) {
					actions["team_id"] = this.$store.getters.team.id;
				}
				this.getScore();
				this.getContest(this.Date.task);
			});
			this.dialog = false;
		},

		// 删除
		deleteItemConfirm() {
			delContest(this.Date.id).then(() => {
				let actions = { type_id: "home_life" };
				if (this.$store.getters.team) {
					actions["team_id"] = this.$store.getters.team.id;
				}
				this.getScore();
				this.getContest(this.Date.task);
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
			this.dialogDelete = false;
		},
	},
};
</script>

<style scoped>
</style>